import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";
import SEO from "../../../common/SEO";
import Layout from "../../../common/Layout";
import BreadcrumbOne from "../../../common/breadcrumb/BreadcrumbOne";
import FooterOne from "../../../common/footer/FooterOne";
import {Link} from "react-router-dom";

const ServicesPage = () => {
    const isHomePage = window.location.pathname === "/";
    const pathArray = window.location.pathname.split("/");
    const currentService = window.location.pathname.split("/")[pathArray.length - 1];
    const allServices = [
        {
            id: "gastro",
            title: "Happy Gastro: ",
            img: "https://images.pexels.com/photos/735869/pexels-photo-735869.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            description: (
                <>
                    <strong>
                        Éttermi szoftverünk mindent megkönnyít, hogy te csak a vendéglátásra koncentrálhass:
                    </strong>
                    <ul>
                        <li><strong>Házhozszállítás modul:</strong> Egyszerű és gyors rendeléskezelés, hogy az ételeid
                            mindig
                            időben
                            érkezzenek meg a vendégekhez.
                        </li>
                        <li><strong>Asztaltérkép modul:</strong> Könnyen áttekinthető asztalkezelés, hogy mindig tudd,
                            hol van
                            szabad
                            hely.
                        </li>
                        <li><strong>Happy mobil pincér:</strong> Mobilos rendelésfelvétel, amivel a pincérek kevesebb
                            idő
                            alatt több
                            vendéget tudnak kiszolgálni.
                        </li>
                        <li><strong>Futár modul:</strong> Futárok nyomon követése és egyszerű irányítása, hogy minden
                            szállítás
                            gördülékenyen menjen.
                        </li>
                        <li><strong>Pénztárgép integráció:</strong> Zökkenőmentes fizetési folyamatok a kasszánál.</li>
                        <li><strong>Manager / Tulajdonos statisztikák modul:</strong> Részletes jelentések, amelyek
                            segítenek
                            nyomon
                            követni és javítani az étterem teljesítményét.
                        </li>
                        <li><strong>Készlet / raktár modul:</strong> Pontos készletnyilvántartás, hogy mindig tudj a
                            készletekről és
                            elkerüld a hiányokat.
                        </li>
                    </ul>
                    <strong>
                        És még sok más: Fedezd fel a további funkciókat, amelyekkel éttermed mindennapjai
                        gördülékenyebbé válnak!
                    </strong>
                    <div className="read-more-btn mt--10">
                        <Link className="edu-btn" to="https://happygastro.hu/">Tovább az oldalra<i
                            className="icon-arrow-right-line-right"></i></Link>
                    </div>
                </>
            ),
            reversed: false,
        },
        {
            id: "trade",
            title: "Happy Trade:",
            img: "https://images.pexels.com/photos/6969962/pexels-photo-6969962.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            description: (
                <>
                    <strong>
                        Kereskedelmi szoftverünk segítségével könnyedén kezelheti üzletét
                    </strong>
                    <div style={{paddingTop: "1rem"}}>
                        <ul>
                            <li>Felhasználóbarát platform a kisvállalkozások és kiskereskedők számára a termékek egyszerű
                                kezeléséhez
                                és értékesítéséhez.
                            </li>
                            <li>Készletkezelés, számlázás és értékesítési elemzések egyetlen felületen, hogy minden üzleti
                                adat kéznél
                                legyen.
                            </li>
                            <li>Integrált fizetési és szállítási megoldások a zökkenőmentes vásárlási élmény érdekében.</li>
                            <li>Skálázható megoldások a növekvő vállalkozások igényeinek kiszolgálására.</li>
                        </ul>
                    </div>
                </>
            ),
            reversed: true,
        },
        {
            id: "energy",
            title: "Happy Energy: ",
            img: "https://images.unsplash.com/photo-1548613053-22087dd8edb8?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: (
                <>
                    <strong>
                        Happy Energy segítségével nemcsak energiát adunk,
                        hanem életet is ragyogóbbá teszünk. Innovatív technológiákkal és a
                        zöldebb jövő iránti elkötelezettséggel tesszük a tiszta energiát
                        mindenki számára egyszerűvé, megfizethetővé és hatékonnyá.
                    </strong>
                    <ul>
                        <li>Rugalmas és megfizethető energia megoldások, amelyek segítenek csökkenteni a környezeti terhelést
                            és az energia költségeket.
                        </li>
                        <li>Okos eszközök és szoftverek az energiafogyasztás optimalizálására és monitorozására.</li>
                        <li>Szakértői tanácsadás a megújuló energiaforrásokra történő átállásban, hogy mindenki számára
                            elérhetővé tegyük a zöld jövőt.
                        </li>
                        <li>Környezetbarát technológiák, amelyek növelik az energiahatékonyságot és csökkentik a
                            szénlábnyomot.
                        </li>
                    </ul>
                </>
            ),
            reversed: false,
        },
        {
            id: "project",
            title: "Happy Project:",
            img: "https://plus.unsplash.com/premium_photo-1661290256778-3b821d52c514?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: (
                <>
                    <strong>
                        A Happy Project segít vállalkozásoknak és csapatoknak átláthatóan és hatékonyan kezelni projektjeiket az
                        ötlettől a megvalósításig. Egyszerűsíti a munkafolyamatokat, hogy a célok elérésére összpontosíthassanak.
                    </strong>
                    <ul>
                        <li>Projekttervezés és feladatkövetés intuitív felületen, hogy mindenki tudja, mi a következő
                            lépés.
                        </li>
                        <li>Csapattagok közötti kommunikáció és együttműködés hatékony eszközökkel a sikeres projektek
                            érdekében.
                        </li>
                        <li>Idő- és költségkezelési funkciók, amelyek biztosítják, hogy minden projekt időben és
                            költségkereten belül valósuljon meg.
                        </li>
                        <li>Testreszabható jelentések és analitikák a projekt teljesítményének folyamatos nyomon
                            követésére és javítására.
                        </li>
                    </ul>
                </>
            ),
            reversed: true,
        },
        {
            id: "salon",
            title: "Happy Salon: ",
            img: "https://plus.unsplash.com/premium_photo-1664301489002-2fed4596c101?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            description: (
                <>
                    <strong>
                        A Happy Salon modern megoldásaival a kisvállalkozások, mint a fodrász- és körömszalonok,
                        kiemelkedő szolgáltatást
                        nyújthatnak vendégeiknek. Innovatív eszközeink és támogatásunk segítségével a
                        szalonok könnyedén emelhetik a színvonalat, hogy vendégeik mindig elégedetten távozzanak.
                    </strong>
                    <div>
                        <ul>
                            <li>Professzionális ügyfélkezelő szoftver a szalonok számára, amely megkönnyíti az
                                időpontfoglalásokat és a vendégek kezelését.
                            </li>
                            <li>Részletes statisztikák és riportok az üzleti teljesítmény nyomon követésére és
                                optimalizálására.
                            </li>
                            <li>Marketing eszközök, amelyek segítenek elérni és megtartani a vendégeket, például emlékeztetők és
                                ajánlatok küldése.
                            </li>
                            <li>Integráció különböző fizetési rendszerekkel, hogy a fizetés zökkenőmentes és gyors legyen.</li>
                        </ul>
                    </div>
                </>
            ),
            reversed: false,
        },
    ];
    const [data, setData] = React.useState(allServices.filter(d => d.id === currentService));
    React.useEffect(() => {
        if(currentService === "termekek") {
            setData(allServices);
        }else{
            setData(allServices.filter(d => d.id === currentService))
        }
        console.log("currentService", currentService);
        console.log("data", data);
    }, [currentService]);

    return (
        <>
            {!isHomePage && <SEO title="Termékeink"/>}
            <Layout>

                <div
                    className="edu-about-area eduvibe-contact-us about-style-3 bg-image happy-services"
                    style={{padding: "4rem 0"}}
                >
                    <div className="container eduvibe-animated-shape">
                        {data.map((row) => {
                            return (
                                <div
                                    className={`d-flex ${row.reversed ? "reversed" : ""}`}
                                    id={row.title}
                                    style={{
                                        flexDirection: !row.reversed ? "row" : "row-reverse",
                                        alignItems: "center",
                                        gap: "6rem",
                                    }}
                                >
                                    <div className="inner" style={{ width: "100%" }}>
                                        <SectionTitle classes="text-start" title={row.title} />
                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}
                                        >
                                            <p className="description mt--40">{row.description}</p>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="inner" style={{ maxWidth: "fit-content" }}>
                                        <ScrollAnimation
                                            animateIn="fadeIn"
                                            animateOut="fadeInOut"
                                            className="testimonial-left-image"
                                            animateOnce={true}
                                            style={{ margin: "5rem 0" }}
                                        >
                                            <div className="thumbnail">
                                                <div
                                                    className="circle-image-wrapper"
                                                    style={{ width: "28rem" }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "26rem",
                                                            height: "26rem",
                                                            objectFit: "cover",
                                                        }}
                                                        className="radius-round"
                                                        src={row.img}
                                                        alt="Futár modul"
                                                    />
                                                    <div className="circle-image">
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Layout>
            {!isHomePage && <FooterOne />}
        </>
    );
};
export default ServicesPage;
