import React, { useState } from "react";
import SEO from "../../src/common/SEO";
import Layout from "../../src/common/Layout";

import AboutOne from "../components/about/AboutOne";
import AboutTwo from "../components/about/AboutTwo";
import AboutThree from "../components/about/AboutThree";
import AboutFour from "../components/about/AboutFour";
import AboutFive from "../components/about/AboutFive";
import AboutSix from "../components/about/AboutSix";
import AboutUsOneService from "../components/about-us-one/AboutUsOneService";
import AboutUsOneTeam from "../components/about-us-one/AboutUsOneTeam";
import AboutUsTwoCountDown from "../components/about-us-two/AboutUsTwoCountDown";
import AboutUsTwoInstructor from "../components/about-us-two/AboutUsTwoInstructor";
import AboutUsTwoService from "../components/about-us-two/AboutUsTwoService";
import AboutUsThreeMission from "../components/about-us-three/AboutUsThreeMission";
import AboutUsThreeInstructor from "../components/about-us-three/AboutUsThreeInstructor";
import BannerOne from "../components/banner/BannerOne";
import BannerTwo from "../components/banner/BannerTwo";
import BannerThree from "../components/banner/BannerThree";
import BannerFour from "../components/banner/BannerFour";
import BannerFive from "../components/banner/BannerFive";
import DemoBanner from "../components/banner/DemoBanner";
import downloadBanner from "../components/banner/downloadBanner";
import integrations from "../components/banner/integrations";
import BannerLanding from "../components/banner/BannerLanding";
import AwardOne from "../components/award/AwardOne";
import CategoryOne from "../components/category/CategoryOne";
import CategoryTwo from "../components/category/CategoryTwo";
import CategoryThree from "../components/category/CategoryThree";
import CategoryFour from "../components/category/CategoryFour";
import ComingSoonOne from "../components/coming-soon/ComingSoonOne";
import CounterUpOne from "../components/counterup/CounterUpOne";
import RelatedCourses from "../components/course/RelatedCourses";
import CourseInfo from "../components/course/CourseInfo";
import CourseTypeOne from "../components/course/CourseTypeOne";
import CourseTypeThree from "../components/course/CourseTypeThree";
import CourseTypeFour from "../components/course/CourseTypeFour";
import CourseItemsForFilter from "../components/course/CourseItemsForFilter";
import Filters from "../components/course/Filters";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import CallToActionOne from "../components/cta/CallToActionOne";
import CallToActionTwo from "../components/cta/CallToActionTwo";
import EventOne from "../components/event/EventOne";
import EventTwo from "../components/event/EventTwo";
import FeatureOne from "../components/feature/FeatureOne";
import FeatureTwo from "../components/feature/FeatureTwo";
import FeatureThree from "../components/feature/FeatureThree";
import PostStandard from "../components/post/PostStandard";
import TestimonialOne from "../components/testimonial/TestimonialOne";
import TestimonialTwo from "../components/testimonial/TestimonialTwo";
import LogoOne from "../components/logos/LogoOne";
import InstructorOne from "../components/instructor/InstructorOne";
import InstructorTwo from "../components/instructor/InstructorTwo";
import InstructorThree from "../components/instructor/InstructorThree";


function CourseTypeTwo() {
    return null;
}

function CourseTypeFive() {
    return null;
}

const PricingPlan = ({ formStyle }) => {

    const sampleData = {
        id: 1,
        details: "This is a sample blog post detail. It should be long enough to be truncated.",
        image: "sample-image.jpg",
        categories: ["Category1", "Category2"],
        view: 100,
        comment: 10,
        date: "2023-10-01",
        title: "Sample Blog Post",
        designation: "designation"
    };
  return (
    <>
      <SEO
        title="Árajánlat"
        description={
          "Csomagjaink, moduljaink és eszközeink árai - árajánlat kérése, demó igénylése"
        }
      />
      <Layout>

          <div
              className="eduvibe-contact-us edu-contact-us-area bg-color-white"
              style={{padding: "4rem 0"}}
          >
              <h3>Komponens tesztelés:</h3>
              <h5>1. AboutOne</h5>
              <AboutOne/>
              <h5>2. AboutTwo</h5>
              <AboutTwo/>
              <h5>3. AboutThree</h5>
              <AboutThree/>
              <h5>4. AboutFour</h5>
              <AboutFour/>
              <h5>5. AboutFive</h5>
              <AboutFive/>
              <h5>6. AboutSix</h5>
              <AboutSix/>
              <h5>7. AboutUsOneService</h5>
              <AboutUsOneService/>
              <h5>8. AboutUsOneTeam</h5>
              <AboutUsOneTeam/>
              <h5>10. AboutUsTwoCountDown</h5>
              <AboutUsTwoCountDown/>
              <h5>11. AboutUsTwoInstructor</h5>
              <AboutUsTwoInstructor/>
              <h5>12. AboutUsTwoService</h5>
              <AboutUsTwoService/>
              <h5>13. AboutUsThreeMission</h5>
              <AboutUsThreeMission/>
              <h5>14. AboutUsThreeInstructor</h5>
              <AboutUsThreeInstructor/>
              <h5>14. BannerOne</h5>
              <BannerOne/>
              <h5>15. BannerTwo</h5>
              <BannerTwo/>
              <h5>16. BannerThree</h5>
              <BannerThree/>
              <h5>17. BannerFour</h5>
              <BannerFour/>
              <h5>18. BannerFive</h5>
              <BannerFive/>
              <h5>19. BannerLanding</h5>
              <BannerLanding/>
              <h5>20. DemoBanner</h5>
              <DemoBanner/>
              <h5>awardOne</h5>
              <AwardOne/>
                <h5>CategoryOne</h5>
                <CategoryOne/>
                <h5>CategoryTwo</h5>
                <CategoryTwo/>
                <h5>CategoryThree</h5>
                <CategoryThree/>
                <h5>CategoryFour</h5>
                <CategoryFour/>
                <h5>ComingSoonOne</h5>
                <ComingSoonOne/>
                <h5>counterup</h5>
                <CounterUpOne/>
                <h5>SectionTitle</h5>
                <SectionTitle
                    classes = "text-center"
                    slogan = "What We Offer"
                    title = "Learn New Skills When And <br /> Where You Like"/>
              <h5>CallToActionOne</h5>
                <CallToActionOne/>
              <h5>CallToActionTwo</h5>
                <CallToActionTwo/>
              eventOne

                <EventOne data={{id:"id", nev: "Név", adoszam: "adoszam", cim: "cím"}}/>
              eventTwo
                <EventTwo data={{id:"id", nev: "Név", adoszam: "adoszam", cim: "cím"}}/>
              featureOne
                <FeatureOne data={{id:"id", nev: "Név", adoszam: "adoszam", cim: "cím"}}/>
                featureTwo
                <FeatureTwo data={{id:"id", nev: "Név", adoszam: "adoszam", cim: "cím"}}/>
                featureThree
                <FeatureThree data={{id:"id", nev: "Név", adoszam: "adoszam", cim: "cím"}}/>
                postStandard
              <PostStandard data={sampleData} classes="custom-class" />
                logoOne
              <LogoOne/>






          </div>

      </Layout>
    </>
  );
};
export default PricingPlan;
