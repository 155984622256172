import React from 'react';
import { Link } from 'react-router-dom';

const BannerThree = () => {
    return (
        <div className="slider-area banner-style-3 bg-image">
            <div className="d-flex align-items-center height-940">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="inner">
                                <div className="content text-start">
                                    <span className="pre-title">Próbálja ki díjmentesen, kockázat nélkul:</span>
                                    <h1 className="title" >Innovativ Szoftver megoldások a Vendéglátásban</h1>
                                    <p className="description" style={{fontSize: 16}}>
                                        A legújabb/legfrissebb technikai alapok felhasználásával készült innovatív szoftver megoldások a vendéglátásban, ami több évtizedes tapasztalattal készült a hazai vendéglátás és a vendéglátós szoftverek nemzetközi tendenciáninak figyelembe vételével, így egy mindenki által kezelhető, minden/egyedi kívánságot kielégítő rugalmas szoftvert tudunk Önnek nyújtani/prezentálni.
                                    </p>
                                    <Link className="edu-btn" to="/termekeink/happy-pos">Próbálja ki most <i className="icon-arrow-right-line-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-image">
                                <div className="banner-main-image">
                                    <img className="img-01" src="https://i.postimg.cc/ydH5HjFX/image.png" alt="Happy Gastro™ app screenshot" />
                                </div>
                                {false && <img className="img-02" width={300} src="https://i.postimg.cc/vmY7PQ8p/mockup-1.png" alt="Banner Images" />}
                                {false && <img className="img-03" width={500} src="https://i.postimg.cc/LXwWG9v5/Group-2.png" alt="Banner Images" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerThree;
