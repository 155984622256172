import React from "react";
import {Link} from "react-router-dom";
import Nav from "./Nav";
import packageJson from "../../../package.json";

const ResponsiveMenu = ({show, onClose, showSearch, onSearch}) => {
    const elements = document.querySelectorAll(
        ".popup-mobile-menu .has-droupdown > a"
    );
    const elementsTwo = document.querySelectorAll(
        ".popup-mobile-menu .with-megamenu > a"
    );
    const links = document.querySelectorAll(".has-droupdown .submenu li a");
    const menus = document.querySelectorAll(".popup-mobile-menu");
    links.forEach(function (element) {
        element.onclick = function () {
            menus.forEach((menu) => {
                menu.classList.remove("active");
            });
        };
    });
    elements.forEach(function (element) {
        element.onclick = function () {
            this.parentElement.querySelector(".submenu").classList.toggle("active");
            this.classList.toggle("open");
        };
    });

    elementsTwo.forEach(function (element) {
        element.onclick = function () {
            this.parentElement
                .querySelector(".rn-megamenu")
                .classList.toggle("active");
            this.classList.toggle("open");
        };
    });

    React.useEffect(() => {
        document?.addEventListener('click', (e) => {
            if(e?.target?.classList?.contains('active'))
                onClose()
        })
    }, [])

    return (
        <>
            <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
                <div className="inner">
                    <div className="header-top">
                        <div
                            className="logo"
                            style={{display: "flex", alignItems: "center"}}
                        >
                            <Link to={process.env.PUBLIC_URL + "/"}>
                                <img
                                    className="logo-light"
                                    src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png"
                                    alt="Happy Solutions™ Logo"
                                />
                            </Link>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {<span
                                    style={{
                                        fontWeight: 100,
                                        fontSize: 12,
                                        position: "absolute",
                                        bottom: 25,
                                    }}
                                >
                  (v{packageJson.version})
                </span>}
                                <h4
                                    className="m-0"
                                    style={{fontSize: 25, whiteSpace: "nowrap", width: 246}}
                                >
                                    Happy Solution <br/>
                                    <small>
                                        Komplex megoldások
                                    </small>
                                </h4>
                            </div>
                        </div>
                        <div className="close-menu" style={{width: 100}}>
                            <button className="close-button" onClick={onClose}>
                                <i className="ri-close-line"></i>
                            </button>
                        </div>
                    </div>
                    <Nav/>

                </div>
            </div>

            <div className={`edu-search-popup ${showSearch ? "open" : ""}`}>
                <div className="close-button">
                    <button className="close-trigger" onClick={onSearch}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
                <div className="inner">
                    <form className="search-form" action="#">
                        <input
                            type="text"
                            className="eduvibe-search-popup-field"
                            placeholder="Search Here..."
                        />
                        <button className="submit-button">
                            <i className="icon-search-line"></i>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResponsiveMenu;
