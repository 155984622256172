import React, {useState} from 'react';
import yaml from 'js-yaml';
import axios from "axios";

const DownloadBanner = () => {
    const [show, setShow] = useState(!localStorage.getItem('downloadBannerClosed'));
    const [latestYamlContent, setLatestYamlContent] = React.useState(null);

    React.useEffect(() => {
        axios.get('https://happy-releases.fra1.cdn.digitaloceanspaces.com/distributions/windows/latest.yml')
            .then(response => {
                try {
                    const parsedData = yaml.load(response.data);
                    const files = parsedData.files;
                    if (files && Array.isArray(files)) {
                        for (const file of files) {
                            setLatestYamlContent(file.url)
                        }
                    } else {
                        console.log("No files found in the data.");
                    }
                } catch (e) {
                    console.log("Error parsing YAML:", e);
                }
            })
            .catch(error => {
                console.error('Error fetching latest.yaml:', error);
            });
    }, []);

    const handleDownload = async () => {
        const origin = 'web';
        if (origin === 'web' && latestYamlContent) {
            try {
                const url = `https://happy-releases.fra1.cdn.digitaloceanspaces.com/distributions/windows/${latestYamlContent}`;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'HappyPOS.yaml');
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        }
    };

    if (!show) return null;

    return (
        <div className="download-banner slider-area banner-style-2 bg-image">
            <div className={'close'} onClick={() => {
                localStorage.setItem('downloadBannerClosed', true);
                setShow(false);
            }}>
                <i className='close bx bx-x'/>
            </div>
            <div className="d-flex align-items-center justify-content-center"
                 style={{padding: '4rem 1rem', gap: '4rem'}}>
                <div className="pt_md--0 pt_sm--0">
                    <div className="content text-start">
                        <h3 style={{whiteSpace: 'nowrap'}}>Letöltötted már szoftverünket?</h3>
                        <strong className="description m-0" style={{fontSize: '1.85rem'}}>A gombra kattintva azonnal
                            megteheted</strong>
                    </div>
                </div>
                <div className="card-info bounce-slide" style={{cursor: 'pointer'}}>
                    <button className="button edu-btn btn-medium left-icon" onClick={() => {
                        handleDownload()
                    }}>
                        <i className='bx bxs-download'/>
                        <span>Letöltöm</span>
                    </button>
                </div>
            </div>
            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none"
                 style={{transform: 'translateY(-340px)'}}>
                <div className="shape-image shape-image-1">
                    <img src="/images/shapes/shape-07.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-2">
                    <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-3">
                    <img src="/images/shapes/shape-05-04.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-4">
                    <img src="/images/shapes/shape-06.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-5">
                    <img src="/images/shapes/shape-01-03.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-6">
                    <img src="/images/shapes/shape-01-04.png" alt="Shape Thumb"/>
                </div>
            </div>
        </div>
    );
};

export default DownloadBanner;
