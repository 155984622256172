import React, { useState } from "react";
import SEO from "../../src/common/SEO";
import Layout from "../../src/common/Layout";
import BreadcrumbOne from "../../src/common/breadcrumb/BreadcrumbOne";
import SearchCompany from "../components/contact/SearchCompany";
import serialize from "form-serialize";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Checkbox } from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import sha256 from "sha256";

const Result = ({ result, setResult }) => {
  return (
    <div>
      <div className="alert alert-success text-center" role="alert">
        <h4
          className="alert-heading text-center"
          style={{ fontSize: "2.4rem" }}
        >
          Köszönjük üzenetét, hamarosan keresni fogjuk!
        </h4>
      </div>

      <br />
      <br />
      <p>
        Köszönjük, hogy kérdésével felkereste ügyfélszolgálatunkat. Bejelentését
        a {result} jegyszámon tartjuk nyilván.
        <br />
        Telefonos érdeklõdés esetén kérjük ezt a kódot megadni.
        <br />
        <br />
        Kollégáink megvizsgálják a beküldött üzenetet és annak megfelelõen
        keresni fogják Önt e-mailben vagy telefonon.
        <br />
        <br />
        További kérdés esetén készséggel állunk rendelkezésére a{" "}
        <a href={"tel:+3613009105"}>+36-1-300-91-05</a>-ös telefonszámon vagy
        e-mailben{" "}
        <a
          href={"mailto:support@happygastro.hu"}
          target={"_blank"}
          rel="noreferrer"
        >
          support@happygastro.hu
        </a>{" "}
        címen.
      </p>
      <br />

      <div className="col-lg-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <button
          className="rn-btn edu-btn w-100"
          type="submit"
          onClick={() => setResult(false)}
        >
          <i className="icon-arrow-left-line-right"></i>
          <span>Új üzenet írása</span>
        </button>
      </div>
    </div>
  );
};

const Demo = ({ formStyle }) => {
  const [result, setResult] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showDemoForm, setShowDemoForm] = React.useState(false);
  const [acceptAszf, setAcceptAszf] = React.useState(false);
  const [acceptNewsletter, setAcceptNewsletter] = React.useState(true);

  const formRef = React.useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    if (!acceptAszf)
      return Swal.fire({
        icon: "error",
        title: "Valami nem sikerült,",
        text: "Kérjük fogadja el Általános Szerződési feltételeinket!",
        confirmButtonColor: "#f1b44c",
      });
    let formData = serialize(formRef.current, { hash: true, empty: true });
    axios({
      method: "post",
      url: `${window.api}/demo/request`,
      headers: { "Content-Type": "application/json" },
      data: {
        ...formData,
      },
    })
      .then((res) => {
        setResult(res.code);
        e.target.reset();
        window.gtag("event", "demo_request", {
          method: "Happy Gastro Web demo request",
          ...formData,
        });
        window.gtag("event", "demo_request", {
          method: "Happy Gastro Web demo request",
        });
        setSelectedCompany(null);
        Swal.fire({
          icon: "success",
          title: "Sikeres regisztráció!",
          text: "Hozzáférései elkészítése folyamatban van, a következő oldalon részletesen tájékoztatjuk a továbbiakról!",
          confirmButtonColor: "#f2b34c",
          timer: 8000,
          timerProgressBar: true,
        }).then((res) => {
          setSelectedCompany(null);
          setShowDemoForm(false);
        });
      })
      .catch((error) => {
        setResult(false);
        Swal.fire({
          title:
            "Sajnáljuk, de nem várt technikai hiba lépett fel. Kérjük próbálja újra késöbb, vagy keressen minket ügyfélszolgálati vonalaink egyikén!",
          confirmButtonColor: "#f2b34c",
          timer: 3000,
          timerProgressBar: true,
        });
      });
  };
  const isHomePage = window.location.pathname === "/";
  return (
    <>
      {!isHomePage && <SEO title="Demo igénylés" />}
      <Layout>
        <BreadcrumbOne />
        <Modal
          show={showDemoForm}
          onHide={() => {
            setShowDemoForm(false);
            setSelectedCompany(null);
          }}
          centered
        >
          <Modal.Body style={{ padding: "3rem" }}>
            <div className="col-lg-12">
              <h6 className={"title"} style={{ textAlign: "center" }}>
                Néhány adat és már igényelheti is demó hozzáférését:
              </h6>
            </div>
            <div className="d-flex" style={{ padding: "2rem 0" }}>
              <form
                name={"contact-form"}
                id={"contact-form demo-register-form"}
                ref={formRef}
                onSubmit={sendEmail}
                style={{
                  width: "85%",
                  margin: "0 auto",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                <div className="col-lg-12 p-0">
                  <div className="form-group">
                    <input
                      style={{ background: "#fff" }}
                      type="text"
                      className="form-control form-control-lg"
                      id={"fullname"}
                      name="fullname"
                      placeholder="Teljes neve*"
                      required
                    />
                  </div>
                </div>
                {false && (
                  <div className="col-lg-12 p-0">
                    <div className="form-group">
                      <SearchCompany
                        style={{ background: "#fff" }}
                        selectedCompany={selectedCompany}
                        onSelect={(c) => {
                          setSelectedCompany(c);
                          document.getElementById("search-input").value = "";
                          document.getElementById("search-input").focus();
                          //props.setRegisteredData(c)
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="col-lg-12 p-0">
                  <div className="form-group">
                    <input
                      style={{ background: "#fff" }}
                      type="email"
                      className="form-control form-control-lg"
                      id={"email"}
                      name="email"
                      placeholder="Email*"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12 p-0">
                  <div className="form-group">
                    <input
                      style={{ background: "#fff" }}
                      type="phone"
                      className="form-control form-control-lg"
                      id={"phone"}
                      name="phone"
                      placeholder="Telefonszám*"
                      required
                    />
                  </div>
                </div>
                <div
                  className="aszf"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={acceptAszf}
                    onChange={(e) =>
                      setAcceptAszf((prevState) => {
                        return !prevState;
                      })
                    }
                  />
                  <a
                    href={`${window.location.origin}/aszf`}
                    target="_blank"
                    rel="noreferer noreferrer"
                  >
                    <p className={"m-0"}>
                      Átlalános Szerződési Feltételek elfogadása<sup>*</sup>
                    </p>
                  </a>
                </div>
                <div className="col-lg-12">
                  <div
                    className="aszf"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Checkbox
                      checked={acceptNewsletter}
                      onChange={(e) =>
                        setAcceptNewsletter((prevState) => {
                          return !prevState;
                        })
                      }
                    />
                    <p className={"m-0"}>
                      Feliratkozom a Happy Solution hírlevelére.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 p-0 d-flex justify-content-center">
                  <button className="rn-btn edu-btn w-50" type="submit">
                    <span>Küldés</span>
                    <i className="icon-arrow-right-line-right"></i>
                  </button>
                </div>
              </form>
            </div>
            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div
                className="shape-image shape-image-1"
                style={{
                  position: "absolute",
                  top: "20%",
                }}
              >
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div
                className="shape-image shape-image-2"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "25px",
                }}
              >
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              <div
                className="shape-image shape-image-3"
                style={{
                  position: "absolute",
                  bottom: "5%",
                  left: "25%",
                }}
              >
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className="gappery-wrapper"
                    delay={150}
                    animateOnce={true}
                  >
                    <span className="pre-title">
                      Csatlakozzon elégedett partnereink közé és próbálja ki
                      szoftvereinket
                    </span>
                    <h3 className="title">Igényeljen demót</h3>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "2rem" }}>
              {!!result ? (
                <Result setResult={setResult} result={result} />
              ) : (
                <form
                  className={`${formStyle}`}
                  action=""
                  name={"contact-form"}
                  id={"contact-form"}
                  onSubmit={(e) => {
                    e.preventDefault();
                    //sendEmail
                    setShowDemoForm(true);
                  }}
                >
                  <br />
                  <div className="col-lg-12 d-flex justify-content-center">
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      className=""
                      delay={300}
                      animateOnce={true}
                    >
                      <button
                        className="rn-btn edu-btn"
                        style={{ width: "fit-content" }}
                        type="submit"
                      >
                        <span style={{ whiteSpace: "nowrap" }}>Kipróbálom</span>
                        <i className="icon-arrow-right-line-right"></i>
                      </button>
                    </ScrollAnimation>
                  </div>
                </form>
              )}
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Demo;
