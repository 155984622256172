import React, { useState, useContext } from "react";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import FooterOne from "../../common/footer/FooterOne";

const faqCategories = [
  {
    id: 1,
    slug: "software-features",
    label: "Funkciók",
  },
  {
    id: 2,
    slug: "technology-and-security",
    label: "Technológia / biztonság",
  },
  {
    id: 3,
    slug: "laws-and-ntak",
    label: "Jogszabályi környezet",
  },
  {
    id: 4,
    slug: "errors",
    label: "Hibalehetőségek",
  },
  {
    id: 5,
    slug: "other",
    label: "Egyéb",
  },
];

const softwareFeatures = {
  leftColumn: [
    {
      title: "A program kezel készletet?\n",
      body:
        "Igen, pontosan nyilvántartható az aktuális alapanyag, félkész és késztermék raktárkészlete.\n" +
        "Minden alkalommal, amikor eladásra kerül sor, a program levonja a felhasznált alapanyagot a megfelelő mennyiségben vagy terméket a megadott mennyiségben. Lehetőség van meglévő készlet átmozgatására raktárak és telephelyek között. Lehetőséged van bevételezni, standolni, leltározni és selejtezni is. Minden tranzakciót rögzít a rendszer, így könnyen visszakereshető, az áru mozgása. \n",
    },
    {
        title: "Van lehetőség online rendelésnél megadni szállítási időt?",
        body: `Igen, Foodora, Wolt valamint saját (Happy Solutions által működtetett) webshop esetében megadható szállítási idő.
        (Ez az időtartam platformok szerint változhat!)`
    },
    {
        title: "Hogyan lehet terméket keresni?",
        body: `A szabadszavas kereső segít könnyen megtalálni bármilyen terméket könnyedén, nem kell foglalkozni, hogy a termék nevét pontosan írjuk be. Ezen felül csoportos bontás is elérhető.`
    },
    {
        title: "Van lehetőség egyedi asztal térképet létrehozni?",
        body: `Igen, speciális asztaltérkép szerkesztőnk segítségével könnyen berendezhetjük a több szintes, vagy több helyiséges éttermünket, van lehetőség az asztalok mellett egyéb tárgyakat is letenni a jobb tájékozódás érdekében.
        Az asztalok formája, színe és mérete teljesen testreszabható.`
    }
  ],
  rightColumn: [
    {
        title: "A Happy Solutions szoftvere tud több felhasználót is kezelni?",
        body: `Igen, különböző jogosultsági szintekkel lehet létrehozni új Managert, Pincért vagy Futárokat is. Ezek mindegyike más és más felületeket ér el.`
    },
    {
        title: "Milyen bejelentkezési lehetőségek vannak?",
        body: `Az első bejelentkezést biztonsági okok miatt e-mail címmel és jelszóval kell elvégezni, de ezután a programon belül beállítható személyes PIN kód vagy akár proxy kárty (NFC), vagy vonalkódos bejelentkezés is.`
    },
    {
        title: "Lehet a termékekhez képet feltölteni?",
        body: `Igen, minden termékhez van lehetőség képet rendelni melyet CDN-en tárolunk a gyorsabb betöltés érdekében.`
    }
  ],
};

const technologyAndSecurity = {
  leftColumn: [
    {
      title: "Milyen technikai igénye van a programnak?",
      body: "A Happy Gastro szofverei minden operációs rendszerre ki lettek fejlesztve, így bátran tudjuk ajánlani mind Windowsos, mind Androis eszközökre is.",
    },
    {
      title: "Mi történik, ha megszakad az internetkapcsolat?",
      body: "Ma már nem jellemző, hogy a szolgáltató ne tudjon állandó internetkapcsolatot biztosítani, természetesen mégis megesik alkalmanként technikai leállás. Ilyen esetekben az a legegyszerűbb megoldás, ha mobiltelefonról hotspot segítségével megosztjuk az internetet.",
    },
    {
      title: "Az adatok biztonságban vannak a felhőben?",
      body: "A Happy Gastro egy online rendszer, de ma már rengeteg ilyen biztonságos rendszert használunk levelezésre, ügyfélkezelésre, akár számlázásra. Rendszerünk folyamatosan szinkronizál a szerverekkel, és egyszerre több tárhelyen történik a mentés, hogy ne fordulhasson elő adatvesztés. A rendszerben beállítható a kiléptetés ideje, ami egy újabb elem hogy az adatok biztonságban legyenek.",
    },
    {
      title: "IMIN szoftverfrissítés",
      body: `Abban az esetben, ha a képernyő kifagy (fekete), vagy lassú az eszköz ezt meg lehet próbálni 1. körben. 	Lépj be az Android beállításaiba, amit a képernyő tetejéről való lehúzás után az értesítési központ jobb alsó sarkában levő fogaskerék ikonnal érsz el.
        A beállítások listájában az utolsó elemre bökve éred el "Az eszköz névjegye" opciót.
        Az eszköz névjegye megnyitása után a második lehetőség a "Vezeték nélküli frissítés". Ezt megnyitva láthatjuk, hogy a rendszerünk naprakész, vagy található hozzá frissítés. Amennyiben van elérhető frissítés a képernyő alján levő "Letöltés" gombbal kezdhetjük el a folyamatot.
        Miután a letöltés és a telepítés is elérte a 100%-ot az eszközt újra kell indítani. Egy felugró ablakban kapunk egy értesítést, ami leírja, hogy az "OK" megnyomása után a már frissített rendszer fog aktiválódni. A "Restart immediately" gomb ugyanezt a funkciót hajtja végre.
        Ha először frissítjük a készüléket, az újraindítás után érdemes újra megnézni, hogy áll-e rendelkezésre további frissítés. Ha a rendszerünk egyszer már naprakész volt, akkor nem valószínű, hogy több frissítés lesz egyszerre.
        Ez akár több percig is eltarthat a frissítés méretétől függően, ezért érdemes olyan időszakban frissíteni, amikor nem történik eladás. A frissítés letöltése előtt ellenőrizzük, hogy az eszköz töltöttségi szintje meghaladja a 30%-ot, ezzel elkerülve a telepítés megszakítását.`,
    },
  ],
  rightColumn: [
    {
      title: "Másik programból lehet importálni adatokat a Happy Gastro-ba?",
      body: "Igen, minden alapanyagot, terméket, és egyéb cikket be lehet tölteni, ehhez egy minta táblázatot biztosítunk.",
    },
    {
      title:
        "Csak a Happy Gastro nyújtotta eszközök, vagy saját számítógépem is alkalmas?",
      body: "A Happy Gastro egy online rendszer, ez olyan előnyökkel jár hogy bármilyen eszközről lehet használni. Az más kérdés, hogy vannak otthoni egyszerű eszközök és vannak profi vendéglátói gépek. Ha a tárcád még nem engedi a profi eszközöket, akkor is van még köztes megoldás. Ha saját eszközt használsz kérd ki munkatársunk véleményét, mielőtt eszközt vásárolnál, mert nekünk nagy gyakorlati ismeretünk van az eszközök használhatósága terén.",
    },
    {
      title: "Kell telepíteni a Happy Gastro-t?",
      body: "Igen, a program egyszerűen néhány kattintással telepíthető és beállítható mind Google Play-ből mind Windows Store-ból.",
    },
    {
      title: "Első indulás",
      body: `Abban az esetben, ha a készüléket először kapcsoltuk be és nem megfelelően erős az internet hálózat okozhat lassulást a rendszerben.
        Az első bekapcsoláskor érdemes stabil, nagy sávszélességű hálózaton összerakni az eszközt, hogy a háttér folyamatok végigmenjenek.
        `,
    },
  ],
};
/*
const pricingPlan = {
    leftColumn: [
        {
            id: 1,
            title: 'Is There Any Prerequisites To Learn Python?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 2,
            title: 'How do I redeem a coupon?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 3,
            title: 'How to Change my Plan using PayPal?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 4,
            title: 'How do I get a refund?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        }
    ],
    rightColumn: [

    ]
}
 */
const lawsAndNtak = {
  leftColumn: [
    {
      title: "A program be van e kötve a NAV-hoz?",
      body: "Az alap verzió nincsen bekötve a NAV-hoz, azaz ebben a formában egy belső készletnyilvántartó, elszámoltató rendszer. A Happy Gastro nyomtatási „proforma” nyugták, azaz nem adóügyi bizonylatok. Adóügyi verzió: A Happy Gastro egy hitelesített pénztárgép vagy egy hitelesített adóügyi nyomtató segítségével végzi a rendszer. Utóbbi esetben a nyomtató adja át a tranzakciós adatokat a NAV részére, de készlet és egyéb információkat nem továbbít.",
    },
    {
      title:
        "NTAK: 2023.01.01.-től minden éttermi rendszernek adatot kell szolgáltatni az NTAK felé, erről itt található bővebb információ",
      body: "A Happy Gastro szoftvere hivatalos NTAK tanúsítvánnyal rendelkezik és az adatszolgáltatásra köteles partnerek esetében a Happy Gastro végzi el az adatszolgáltatási feladatot az eladások esetében. A szoftver használatának megkezdésekor kell egy tanúsítványt hitelesíteni az NTAK oldalán, melyre vonatkozólag útmutatót adunk. Ezek után a felhasználónak nincs több feladata vele.",
    },
    {
      title: "Kell használni pénztárgépet ha Happy Gastro szoftvert használok?",
      body: "Nem, választható hogy vagy a meglévő pénztárgépet köti össze a szoftverrel, vagy adóügyi nyomtatóra vált. Mind a két eszközt hitelesítés után lehet üzemeltetni.",
    },
  ],
  rightColumn: [
    {
      title: "Összeköthető pénztárgéppel a Happy Gastro rendszere?",
      body: "Igen, vannak pénztárgépek, melyek QR kód beolvasásával, vannak melyek USB csatlakozással míg mások LAN (vagy Wi-Fi) megoldásokat használ. Ez minden esetben a pénztárgép típusától függ, kérem tájékozódjon “Szolgáltatások / Eszközeink” menüponton a támogatott márkáinkról.",
    },
    {
      title:
        'A Happy Gastro ismeri a különböző ÁFA típusokat pl "elviteles" vásárlások esetén?',
      body: "Igen, ezáltal támogatja a dolgozók munkáját. Egy adott termék esetében beállítható több áfakulcs: pl. adott étel/ital helyben fogyasztása esetén ÁFA kulcs 5%, ha elvitelre kérik akkor a program automatikusan használja a megfelelő ÁFA kulcsot, ami elvitel esetben 18% vagy 27% lesz. A rendszer automatikusan a megfelelő áfa kulcsot használja a rendelés beérkezése illetve felütése alapján.",
    },
    {
      title: "A Happy Gastro szoftvere NTAK kompatibilis?",
      body: "Igen, a Happy Gastro szoftvere hivatalos NTAK tanúsítvánnyal rendelkezik és az adatszolgáltatásra köteles partnerek esetében a Happy Gastro végzi el az adatszolgáltatási feladatot az eladások esetében. A szoftver használatának megkezdésekor kell egy tanúsítványt hitelesíteni az NTAK oldalán, melyre vonatkozólag útmutatót adunk. Ezek után a felhasználónak nincs több feladata vele.",
    },
  ],
};

const errors = {
  leftColumn: [
    {
      title: "Nem látszik egy vagy több termék.\n",
      body: "Ha nem látszik egy vagy több termék, ellenőrizd az Admin panelen, hogy a Törzsadatok felületen a termék Láthatóként és Eladhatóként is meg van jelölve.",
    },
    {
      title:
        "Indításkor Üdvözlünk a Happy Gastro rendszerében képernyő jelenik meg.",
      body: `Abban az esetben, ha valamilyen internet probléma van induláskor, vagy véletlenül rákattintottunk a POS-on a beállítások / eszközbeállítások / gyorsítótár törlése gombra, akkor a következő induláskor ez az ablak jelenik meg.

        Próbáljuk meg az Admin panel segítségével újra aktiválni a rendszert. NEM kell semmire sem kattintani, csupán a 6 számjegyet kell megadni, az adott eszköznél és újra betölt az app.`,
    },
    {
      title: "Nem működik a nyomtató.",
      body: `Ha nem működnek a nyomtatók, több probléma is okozhatja a jelenséget:
        Internet / belső hálózat probléma
        Beállítás
        Eszköz beállítás
        Nyomtató hiba / nem támogatott nyomtató
    
    
    Fontos tudni, hogy minden esetben készítünk teszt nyomtatást, amikor beállítjuk a nyomtatót valamilyen feladatra (pl rendelés nyomtatás, blokk nyomtatás, pult beállítás).
    Sok esetben viszont előfordul, hogy a kapcsolatot érzékeli a szoftver, de mégsem érkezik ki blokk.
    Vizsgáljuk meg a csatlakozás módját
    USB
    Abban az esetben ha USB-n keresztül van csatlakoztatva a készülék akkor engedélyezni kell a portot (Android), amit úgy tudunk megtenni, hogy amikor csatlakoztatjuk, felugrik egy ablak, amiben ezt meg tudjuk tenni.
    
    Bluetooth
    Abban az esetben ha Bluetooth-os csatlakozást választunk, akkor mindenképpen nézzük meg, hogy párosítva van-e az eszköz.
    Az eszköz párosítás után, a listából a "portok" menüpontnál ki tudjuk választani a nyomtatót.
    
    Hálózat
    Ellenőrizzük, hogy az IP cím megfelelő, illetve azt, hogy a nyomtató ugyanazon az alhálózaton van-e.
    Az IP címet általában egy selftest papír nyomtatásával meg tudjuk tenni (kikapcsolt állapotban a feed gomb hosszan nyomásával és a bekapcsolással).
    
    Beállítások vizsgálata
    Nézzük meg, hogy a nyomtató beállításaiban a Happy Gastrorendszerében ki van-e választva a nyomtató mint "Rendelés nyomtatás be" vagy "Blokknyomtatás". Ha nincs bekapcsolva, akkor ezt tegyük meg és a teszt oldal nyomtatással le tudjuk ellenőrizni, hogy megfelelő-e a kapcsolat.
    Fontos!
    Abban az esetben, ha nincsenek pultok kiválasztva, a rendelés nyomtatás minden rendelést erre a nyomtatóra fog nyomtatni!
    Ha esetleg EPSON protokollon nem működik sehogy sem, akkor érdemes megpróbálni a STAR protokollt, ezt a nyomtató adattábláján meg lehet nézni (neten) , hogy milyen protokollal működik.
    
    Nyomtató beállításai
    Abban az esetben, ha rossz karaktert látunk, vagy egyből hibára dobjuk a kapcsolatot, akkor érdemes a nyomtató beállításait megnézni, a port: 9100 amin alapesetben kommunikálunk.
    Ha egyik módszer se sikerül, próbáljunk meg másképp kapcsolódni a nyomtatóhoz, ha másképp működik, akkor a fentiek valamelyike biztosan nem stimmel, ha más módon se tudunk a nyomtatóhoz kapcsolódni egyik protokollal sem (EPSON, STAR), akkor nagy valószínűség szerint a nyomtató nem támogatott.
    `,
    },
    {
      title: "Nem megfelelő termékeket nyomtat a nyomtató.",
      body: `Nincs, vagy rosszul van beállítva az Admin  felületen a Beállítások / Nyomtatók menüpontban a termékek listázása a megfelelő nyomtatási profilhoz.
        Abban az esetben, ha valamelyik termék nincs pulthoz rendelve, az minden esetben minden rendelés nyomtatón ki fog jönni!
        
        Nyomtató beállítások
        Ellenőrizzük le, hogy a nyomtató beállításoknál megfelelő pultok vannak kiválasztva
        
        Pult beállítások
        Ellenőrizzük le, hogy az adott termék megfelelő pulthoz van-e hozzárendelve Admin panelen.
        `,
    },
    {
        title: "Milyen távsegítség érhető el?",
        body: `TeamViewer vagy AnyDesk segítségével távolról is tudjuk irányítani az eszközt. A programon belül található egy aktív Chat ablak, mely közvetlen  Support csapatunkhoz van bekötve. Ezen kívül munkaidőben központi telefonszámunkon is van lehetőség segítség kérésre. Ezen kívül a programban található Súgó is, mely segít eligazodni a szoftveren belül található gombokkal és piktogramokkal.
        `
    }
  ],
  rightColumn: [
    {
      title:
        "Hiába hoztam létre két méretet, nem jelenik meg a második, hogyan tudnám aktívvá tenni?",
      body: "Amennyiben egy vagy több kiszerelés nem látható, abban az esetben ellenőrizd a Törzsadatok menüpont alatt, hogy a kiszerelés “Eladható” megjelölést kapott-e.",
    },
    {
      title: "Indításkor a Betöltés animáció beragad",
      body: `Abban az esetben, ha indításkor nincs internet kapcsolat, vagy valamiért rossz a szerver kapcsolat és nem tudja lekérni az adatokat.

        Több megoldás is van erre a problémára:
            Indítsuk újra az appot - zárjuk be és nyissuk ki újra
            Indítsuk újra az egész készüléket
            Töröljük a cache-t (Android)
            Hosszan nyomva az app ikonon kattintsunk az "Alkalmazásinformáció" gombra.
            Válasszuk ki a Tárhely és gyorsítótár opciót.
            A gyorsítótár törlése funkcióval tudjuk törölni a cache-t
            Telepítsük újra az appot
        
        
        Fontos! Abban az esetben ha újratelepítjük vagy cache törlést hajtunk végre, az első induláskor mindenképp szükség lesz stabil internet kapcsolatra.
        `,
    },
    {
      title: "Sortöréssel nyomtat a nyomtató.",
      body: `A nyomtató beállításai során, nem jó max karakter szám lett megadva.
        Ebben az esetben a nyomtató eltávolítása szükséges, majd újra csatlakoztatása.
        Nézzük meg, hogy milyen max karakter számmal működik megfelelően a nyomtatás és azt állítsuk be.
        `,
    },
    {
      title: "Nem megy ki az összeg a bankkártya terminálra automatikusan.",
      body: `Abban az esetben, ha van valamilyen bankkártya terminál összekötés mindenféleképpen szükséges aktív internet kapcsolat.
        TEYA esetében a POSLINK összekötést alkalmazzuk, ami neten keresztül kommunikál az eszközzel.
        
        Internet kapcsolat
        Abban az esetben, ha nincs internet kapcsolat, akkor nem fog működni.
        TIPP: Ha huzamosabb ideig nem lesz megoldva az internet kapcsolat, akkor érdemes létrehozni egy bankkártya fizetési módot, ami nincs összekötve a TEYA terminállal, a terminált pedig kézi módba állítani, így tudnak tovább értékesíteni bankkártyás fizetéssel is, csak manuálisan.
        Amint visszatér az internet kapcsolat, vissza is lehet rakni az összekötést.
        Amennyiben van internet (leellenőriztük és minden rendben tényleg) a Terminál beállításokban a Fizetések menüponton válasszuk ki a TEYA POSLINK profilt.
        `,
    },
    {
        title: "Hogyan jelezzem, ha hibát találok?",
        body: `Ha hibát találsz a programban kérjük haladéktalanul jelezd felénk, hogy azt javítani tudjuk. Amennyiben ez nem lehetséges egyből, úgy kérjük, hogy jegyezd fel a hiba idejét és a jelenséget, hogy később is megtalálhassuk azt.`
    }
  ],
};

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button"
    >
      {children}
    </button>
  );
};
const others = {
  leftColumn: [
    {
      title: "Mennyibe kerül a Happy Gastro szoftver és a modulok használata?",
      body: `Áraink nettó árak, az ÁFA-t nem tartalmazzák! Bizonyos modulok ára havidíjas, van amelyiket napidíjban is elérheted.
            Happy Gastro alap előfizetés
            Az alapcsomag mely tartalmazza azokat a funkciókat, amik feltétlen szükségesek egy vendéglátó egység működtetéséhez!
                NTAK kapcsolat
                Bankkártya terminál integráció
                1 felhasználó, 1 POS licensz, 1 kosár
                Felhő alapú működés!
                Asztalok kezelése, bontott fizetés
                Adóügyi integráció
                Termékértékesítés és szerkesztés
                Kedvezmény, Szervízdíj, Borravaló kezelés
                Admin panel & Management app
            
            
            Felhasználókezelés
            Ha szeretnél jogosultságokat és több felhasználót kezelni a POS-on belül.
                Több felhasználó bejelentkezési lehetősége
                Csak POS felhasználó vagy Admin panel és POS, vagy Futár
                Jogosultságok kiosztása
            
            
            
            
            Asztaltérkép
            Ha többre vágysz mint egy asztal lista, vizualizáld interaktív asztaltérkép szerkesztő modullal éttermed felépítését!
                Interaktív szerkesztő
                Egy kattintásos asztal hozzáadás, vizuális blokkokkal
                Székek egyszerű kezelése
            
            
            
            Kosárkezelés
            Rendszerünk több kosár kezelésére is képes, ami azt jelenti, hogy a folyamatban lévő rendelést el tudod menteni.
                Kosár Tartalom mentése
                Végtelen számú kosár nyitási lehetőség
                Új kosár indítása az előző mellett
            
            
            
            ÁFA-s számla kiállítás
            Állíts ki ÁFA-s számlát közvetlenül a Happy Gastro rendszerén keresztül! Számlázz.hu vagy Billingo integrációval.
                Adóügyi nyomtató esetén egyszerűsített számla nyomtatás
                Automatikus adatbeolvasás az adószám alapján
                QR kód megjelenítése a nyugtán ahol le tudják tölteni a számlát PDF formátumban
            
            
            
            Nyomtatás és pult kezelés
            Ha több pultod van és fontos, hogy a rendelésen lévő termékek bemenjenek a konyhára, vagy a kávé / koktél pultba.
                Akármennyi nyomtató hozzáadásának lehetősége
                Pultok alapján nyomtatási beállítások
                Pultok létrehozása és termékek hozzáadása a pultokhoz
            
            
            
            Teljes körű statisztika
            Láss teljes körű statisztikát úgy ahogy te szeretnéd! Ha van olyan ötleted amit szeretnél látni az adatokból, szívesen fogadjuk!
                Részletes statisztika az eladásokból
                Műszakok és online rendelések statisztikája
                Időszakra bontás
            
            
            Franchise modul
            Ha több üzleted van, egy helyen tudod majd kezelni az értékesítést és termék, felhasználó managementet!
                Több lokáció kezelése egy helyen
            
            
                Felhasználók kezelése központilag mindegyik lokációra
            
            
                Termék és méret láthatósági beállítások külön árral
            
            
            
            Házhozszállítás modul
            Kezeld házhozszállítás rendeléseidet a Happy Gastro rendelés kezelőjében integrálva!
                Saját házhozszállítás kezelő rendszer ügyfél rögzítéssel, státuszolással.
                Foodora integráció, státuszkezeléssel és automatikus NTAK beküldéssel (HAMAROSAN)
                WOLT integráció, státuszkezeléssel és automatikus NTAK beküldéssel
            
            
            
            Ha szeretnél szakítani a konyhai blokkokkal és teljesen digitalizálni üzleted, akkor a konyhai kijelző lesz a te választásod!
                Rendelések managementje több szinten
                Napidíjas konstrukció ha csak néhány nap szeretnéd használni
                Rendelések státuszkezelése akár termékenként
            
            
            
            Pincér kézi rendelésfelvevő licensz
            Teljes értékű POS funkciók mobilra optimalizálva
                Teljes értékű POS funkciók a mobilon
                Akár adóügyi összekötéssel fizettetés az asztalnál
                Rendelésfelvétel az asztalnál
            
            
            
            Másodkijelző licensz
            
            Másodkijelzőn mutatjuk a kosár tartalmát, valamint ha olyan a bankkártya integráció, interaktívan tudunk borravalót kezelni.
                Másodkijelzőn kosártartalom megjelenítése
                Interaktív borravaló adás és upsellezés
                Beépített NFC olvasó a könnyű fizetés érdekében Viva Payments segítségével
                Bármilyen tablet lehet, kábeles összekötés nélkül
            
            
            
            
            
            
            
            
            BrandApp és Webshop licensz
            Kommunikálj ügyfeleiddel saját appodon keresztül!
                Online házhoz, elvitelre, vagy helyben fogyasztásra rendelés
                Ha kéred hozzá a VIP modult, a rendelések mellé saját kuponrendszert és pontgyűjtést is hozzá csatolhatsz!
                Beépített bankkártyás fizetés
            
            
            
            VIP Modul - Loyality Program
            Kommunikálj ügyfeleiddel saját appodon vagy webshopon keresztül!
                Kuponkezelés
                Saját függvények létrehozása a beváltáshoz és megszerzéshez.
                Pontgyűjtés és pontok levásárlása
            `,
    },
    {
      title: "Hol látom a visszajáró összegét?",
      body: `Ahhoz, hogy megjelenítsük a visszajárót a képernyőn, lépj be a beállításokba, Testreszabás menüpont, azon belül pedig Működési beállítások. Itt található egy "Pénzösszeg ki" gomb. Amennyiben ez nincs kiemelve, a készpénz fizetési opció mellett kell látnod egy "Pénzösszeg" gombot.
            Kattints a "Pénzösszeg" gombra, és add meg a kapott pénz mennyiségét.
            A rendszer automatikusan kiszámolja és megmutatja a visszajáró összeget a fizetés után.
            `,
    },
    {
      title:
        "A fizetési módot hozzáadtam Admin panelen, de nem jelenik meg a Happy Gastroban.",
      body: `Elég egy újraindítás és máris látszódni fog, ezt a leggyorsabban az infó gomb megnyomásával fogod megtalálni, itt található egy "App újraindítása" gomb. Ez újraindítja az alkalmazást, utána már látszódnia kell az újonnan létrehozott fizetési módnak.`,
    },
    {
      title: "Jó PIN kóddal próbálok belépni, mégsem enged be a rendszer.",
      body: `Az ellenőrzéshez szükséged lesz az Admin panel felületre.
            Lépj a Felhasználók menüpontba, majd az adott felhasználó sorának a végén a kis üzlet ikonra kattintva jön fel a jogosultság, amit kezel a POS rendszere. Amennyiben a felhasználónak nincs jogosultsága a lokáción, ahol próbál belépni, úgy nem fogja beengedni a rendszer.
            
            Egy felhasználónak legalább user azaz felhasználó jogosultsági szintre van szüksége az eladáshoz.
            Magasabb szintű jogosultsággal több beállítás is elérhető a POS-on.
            `,
    },
  ],
  rightColumn: [
    {
      title:
        "Hogy lehet nagyobb betűméretet, termék kártyákat állítani illetve színeket beállítani a POS-on?",
      body: `Lépj a beállításokba a Happy logó segítségével, majd válaszd ki a Beállítások gombot.
            Itt a Megjelenítés részleg alatt találod a méretek beállításának lehetőségét. Itt egy csúszkával könnyen a megfelelő méretre lehet beállítani a programot.
            
            
            Amennyiben túl nagyra állítjuk a Windows rendszer betűméretét, a Happy Gastro-on belül lehetséges, hogy néhány elem takarásba kerül, ezért érdemes ellenőrizni, hogy a megnövelt méret megfelelő megjelenítést hoz magával, mielőtt bezárnánk a betűméret állító ablakot.
            `,
    },
    {
      title:
        "Szeretném beállítani, hogy bizonyos rendelések kifizetésekor ne nyomtasson az adóügyi egység nyugtát.",
      body: `Amennyiben azt szeretnéd elérni, hogy a fizetéskor ne küldje ki a szoftver az adatokat az adóügyi egységre, két lépésre lesz szükség.
            Admin panelen létre kell hozni egy fizetési módot, aminek a típusa átutalás, és ezt hozzá is kell rendelni az adott eszközhöz, amin szükséges lesz egy szoftver újraindítás, hogy megjelenjen az új fizetési mód.
        
        
            Majd a POS-on a Happy Gastro-on belül a beállítások menüpontban, az Adóügyi egység opción belül, ha legörgetsz a képernyő alján megjelenik egy gomb "Utalásos fizetésnél ne nyomtasson" felirattal. 
        
        
        Ha ez be van kapcsolva, és a POS-ra való felütés után fizetéskor az átutalás típusú fizetési módot válasszuk ki, így összesítőt nyomtatunk blokk nyomtatón, de a pénztárgépen nem.
        `,
    },
    {
      title:
        "Sorosan szeretném csatlakoztatni az adóügyi egységet, melyik portot válasszam?",
      body: `Min Swan 1-et használok
            SER/dev/ttyS3
            Sunmi D2S-t használok
            SER/dev/ttyS0
            `,
    },
    {
      title: "Nem látszik a kategória amit létrehoztam.",
      body: `Ellenőrizd, hogy a kategória engedélyezve van e, illetve található-e benne legalább 1 termék, ami szintúgy elérhető, engedélyezve van és látható.`,
    },
  ],
};
const Faq = ({ wrapperClass }) => {
  const [selectedItem, setSelectedItem] = useState("software-features");
  const [activeId1, setActiveId1] = useState("0");
  const [activeId2, setActiveId2] = useState("0");
  const [activeId3, setActiveId3] = useState("0");
  const [activeId4, setActiveId4] = useState("0");
  const [activeId5, setActiveId5] = useState("0");
  const [activeId6, setActiveId6] = useState("0");

  function toggleActive1(id) {
    if (activeId1 === id) {
      setActiveId1(null);
    } else {
      setActiveId1(id);
    }
  }

  function toggleActive2(id) {
    if (activeId2 === id) {
      setActiveId2(null);
    } else {
      setActiveId2(id);
    }
  }

  function toggleActive3(id) {
    if (activeId3 === id) {
      setActiveId3(null);
    } else {
      setActiveId3(id);
    }
  }

  function toggleActive4(id) {
    if (activeId4 === id) {
      setActiveId4(null);
    } else {
      setActiveId4(id);
    }
  }

  function toggleActive5(id) {
    if (activeId5 === id) {
      setActiveId5(null);
    } else {
      setActiveId5(id);
    }
  }

  function toggleActive6(id) {
    if (activeId6 === id) {
      setActiveId6(null);
    } else {
      setActiveId6(id);
    }
  }

  return (
    <>
      <SEO title="Tudástár" description={"Leggyakrabban ismétel kérdésrek"} />
      <Layout>
        <BreadcrumbOne
          title="Tudástár"
          rootUrl="/kapcsolat/tudastar"
          parentUrl="Ügyfélszolgálat"
          currentUrl="Tudástár"
        />
        <div
          className={`edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${
            wrapperClass ? wrapperClass : ""
          } `}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Gyakran ismételt kérdések</span>
                  <h3 className="title">
                    Összegyüjtöttük a leggyakoribban felmerülő kérdéseket
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p className={"mt-5 text-center"}>
                  Ha kérdésed maradt, tekintsd át a leggyakrabban előforduló
                  kérdésekre adott válaszainkat, ami a Happy Gastro online
                  éttermi szoftver használatával, működési hátterével és
                  szabályozási környezetével kapcsolatos tudnivalókat hivatott
                  könnyen átlátni és megérteni.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ul className="edu-tab-nav nav nav-tabs mt--40">
                  {faqCategories.map((button) => (
                    <li className="nav-item" key={button.id}>
                      <button
                        className={
                          button.slug === selectedItem
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label={button.label}
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedItem(button.slug);
                        }}
                      >
                        {button.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {selectedItem === "software-features" && (
              <div className="row g-5 align-items-center mt--20">
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {softwareFeatures.leftColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive1(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId1 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {softwareFeatures.rightColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive2(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId2 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}

            {selectedItem === "technology-and-security" && (
              <div className="row g-5 align-items-center mt--20">
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {technologyAndSecurity.leftColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive3(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId3 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {technologyAndSecurity.rightColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive4(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId4 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}

            {selectedItem === "laws-and-ntak" && (
              <div className="row g-5 align-items-center mt--20">
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {lawsAndNtak.leftColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive5(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId5 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {lawsAndNtak.rightColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive6(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId6 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}

            {selectedItem === "errors" && (
              <div className="row g-5 align-items-center mt--20">
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {errors.leftColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive5(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId5 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {errors.rightColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive6(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId6 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}
            {selectedItem === "other" && (
              <div className="row g-5 align-items-center mt--20">
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {others.leftColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive5(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId5 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" flush>
                      {others.rightColumn.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive6(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId6 === i.toString() ? "active-item" : ""
                          }`}
                        >
                          <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body bsPrefix="edu-accordion-body">
                            {accordion.body}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
      <FooterOne />
    </>
  );
};

export default Faq;
