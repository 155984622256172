import React from 'react';
import { Link } from 'react-router-dom';

const BannerTrade = () => {
    return (
        <div className="slider-area banner-style-3 bg-image">
            <div className="d-flex align-items-center pb--70">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="inner">
                                <div className="content text-start">
                                    <span className="pre-title">Kereskedőktől kereskedőknek</span>
                                    <h1 className="title" >Happy Trade</h1>
                                    <p className="description" style={{fontSize: 16}}>
                                        A Happy Trade egy hatékony belső szoftver, amely leegyszerűsíti az üzlet
                                        minden részletét – a raktárkezeléstől és kiszállítástól kezdve a törzsvásárlók
                                        nyilvántartásáig. Könnyen átlátható felületével időt és energiát takaríthat
                                        meg, miközben biztosítja, hogy minden folyamat gördülékenyen működjön. Akár
                                        egy boltban, akár egy bevásárlóközpontban tevékenykedik, a Happy Trade
                                        segítségével kézben tarthatja vállalkozása minden mozzanatát. Próbálja ki, és emelje üzletét új szintre!
                                    </p>
                                    <Link className="edu-btn" to="/termekek/trade">Bővebben <i className="icon-arrow-right-line-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-image">
                                <div className="banner-main-image">
                                    {/*<img className="img-01" src="https://images.pexels.com/photos/95425/pexels-photo-95425.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Happy Gastro™ app screenshot" />*/}
                                    <img style={{borderRadius: "8px"}} className="img-01" src="/images/mini-cart.jpg" alt="Happy Gastro™ app screenshot" />
                                </div>
                                {false && <img className="img-02" width={300} src="https://i.postimg.cc/vmY7PQ8p/mockup-1.png" alt="Banner Images" />}
                                {false && <img className="img-03" width={500} src="https://i.postimg.cc/LXwWG9v5/Group-2.png" alt="Banner Images" />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerTrade;
