import React from "react";
import Slider from "react-infinite-logo-slider";
const Integrations = () => {
  const integrations = [
    {
      name: "Wolt",
      image:
          "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/wolt.png",
      height: 25,
      href: "https://wolt.com/hu/hun/?origin=www.happysolution.hu",
    },
    {
      name: "Wolt Drive",
      image:
          "https://d21buns5ku92am.cloudfront.net/69308/images/480851-WoltDrive_pill_logo-a8aec6-large-1683188291.png",
      height: 40,
      href: "https://explore.wolt.com/hu/hun/wolt-drive/?origin=www.happysolution.hu",
    },
    {
      name: "Foodora",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/Foodora_Logo_Cherry%20Pink_RGB.png",
      height: 50,
      href: "https://www.foodora.hu/?origin=www.happysolution.hu",
    },
    {
      name: "Novopayment",
      image:
          "/images/logo/novopayment.png",
      height: 68,
      href: "https://novopayment.hu/?origin=www.happysolution.hu",
    },
    {
      name: "Viva payments",
      image:
          "https://mma.prnewswire.com/media/2162532/Viva_Wallet_Logo.jpg?p=twitter",
      height: 37,
      href: "https://www.vivawallet.com/hu_hu/?origin=www.happysolution.hu",
    },

    {
      name: "Teya",
      image:
          "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/teya.png",
      height: 40,
      href: "https://www.teya.com/hu/?origin=www.happysolution.hu",
    },
    {
      name: "Stripe",
      image:
          "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/stripe.png",
      height: 55,
      href: "https://stripe.com/en-hu/?origin=www.happysolution.hu",
    },
    {
      name: "MyPOS",
      image:
          "https://www.mypos.com/info2/info_common/images/logo/logo-header.webp",
      height: 45,
      href: "https://www.mypos.com/hu-hu/?origin=www.happysolution.hu",
    },
    {
      name: "Számlázz",
      image:
          "https://www.szamlazz.hu/wp-content/uploads/2023/09/szamlazzhu_logo-horizontal-1_color.png",
      height: 56,
      href: "https://www.szamlazz.hu/?origin=www.happysolution.hu",
    },
    {
      name: "Billingo",
      image:
          "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/billingo.png",
      height: 30,
      href: "https://www.billingo.hu/?origin=www.happysolution.hu",
    },
    {
      name: "Online Számla",
      info: "Online Számla rendszer, NAV",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsnX2GD5PmJR014CnVf10sSudAznLI0p8Zcw&s",
      href: "https://onlineszamla.nav.gov.hu/?origin=www.happysolution.hu/",
      height: 40,
    },
    {
      name: "iBar",
      info: "Leltározás és standolás éttermeknek",
      image: "https://static.wixstatic.com/media/36b443_d327d78c2c034adcb1cdbec57d24b8a7~mv2.png/v1/fill/w_188,h_98,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ibar_smart_white_edited.png",
      href: "https://www.ibar.ai/?origin=www.happysolution.hu",
      height: 40,
      filter: "invert(1)",
    },
    {
      name: "Querko",
      image:
          "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/qerko.webp",
      height: 50,
      href: "https://www.qerko.com/hu/?origin=www.happysolution.hu",
    },
    {
      name: "MyTyx",
      image:
        "https://mytix.hu/assets/img/logo.png",
      height: 52,
      href: "https://mytix.hu/?origin=www.happysolution.hu",
    },
    {
      name: "Vemoco",
      image:
        "https://www.vemoco.com/uploads/images/vemoco_logo.png",
      height: 37,
      href: "https://www.vemoco.com/hu/home-hu/?origin=www.happysolution.hu",
    },
    {
      name: "Falatozz",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/falatozzhu_logo_szines_v2.svg",
      height: 33,
      href: "https://falatozz.hu/?origin=www.happysolution.hu",
    },
  ];

  return (
    <div style={{ padding: "0 0 20px 0" }}>
      <h3
        className={"title"}
        style={{ whiteSpace: "nowrap", textAlign: "center" }}
      >
        Főbb partnereink
      </h3>
      <div className="integrations-slider">
        <Slider
          width="200px"
          duration={55}
          pauseOnHover={true}
          blurBorders={true}
          blurBoderColor={"#FFF"}
        >
          {integrations.map((integration, index) => (
            <Slider.Slide style={{display: "flex",
              justifyContent: "center",
              alignItems: "center", marginRight: "24px", marginLeft: "24px"}}>
              <a
                href={integration.href}
                rel={"noreferrer"}
                target={"_blank"}
                className="integration-logo"
                key={index}
              >
                <img
                  src={integration.image}
                  alt={integration.name}
                  style={{ height: integration.height, filter: integration.filter }}
                />
              </a>
            </Slider.Slide>
          ))}
        </Slider>
      </div>
      <div style={{padding: "20px 20px 0", textAlign: "center"}}>
        <p style={{margin: 0}}>
          <strong>Érdekli a lehetőség, vagy szeretne partnerünkké válni?</strong> <br/>
          Lépjen kapcsolatba velünk a következő e-mail címen:<br/><a
            href={"mailto:partner@happysolutions.hu?subject=Partneri%20%C3%A9rdekl%C5%91d%C3%A9s"}>partner@happysolutions.hu</a>

        </p></div>

    </div>
  );
};

export default Integrations;
