import React from 'react';
import { Link } from 'react-router-dom';

const BannerSalon = () => {
    return (
        <div className="slider-area banner-style-2 bg-image d-flex align-items-center pt--40 pb--70" style={{minHeight : "auto"}}>
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="inner">
                            <div className="content">
                                <span className="pre-title">Szolgáltatóktól szolgáltatóknak</span>
                                <h1 className="title">Happy Salon </h1>
                                <p className="description">A Happy Salon az ideális szoftver fodrászok, borbélyok, körmösök és kisebb szépségipari vállalkozások
                                    számára. Könnyen kezelhető ügyfél- és készletnyilvántartás, valamint részletes statisztikák segítenek abban,
                                    hogy vállalkozásod gördülékenyen működjön és növekedjen.</p>
                                <div className="read-more-btn">
                                    <Link onClick={(e)=>e.preventDefault()} style={{backgroundColor: "#ccc"}} className="edu-btn">Bővebben <i  className="icon-arrow-right-line-right"></i></Link>
                                </div>
                                <div className="arrow-sign d-lg-block d-none">
                                    <img src="/images/banner/banner-02/arrow.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 mt--185">
                        <div className="banner-thumbnail">
                            <img style={{borderRadius: "8px"}} className="girl-thumb" src="/images/salon-1.jpg" alt="Girl Images" />
                        </div>
                        <div className="banner-bg d-lg-block d-none ">
                            <img className="girl-bg" src="/images/banner/banner-02/girl-bg.png" alt="Girl Background" />
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-19.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-05-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-19-01.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BannerSalon;