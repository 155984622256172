import React from "react";
import { Link as RouterLink } from "react-router-dom";
import "./nav.scss"
const Nav = () => {
  const scrollProps = { spy: true, smooth: true, duration: 500 };
  const isHomePage = false;

  return (
    <ul className="mainmenu">
      <li className="has-droupdown">

          <RouterLink to="/termekek">Termékeink</RouterLink>

        <ul className="submenu">
          <li>
              <RouterLink to="/termekek/gastro">Happy Gastro</RouterLink>
          </li>
          <li>
            {/*<RouterLink to="/termekeink/hardver">Hardver</RouterLink>*/}
              <RouterLink to="/termekek/trade">Happy Trade</RouterLink>
          </li>
          <li>
              <RouterLink className="disabled-button-nav" >Happy Energy</RouterLink>
          </li>
          <li >
              <RouterLink className="disabled-button-nav">Happy Project</RouterLink>
          </li>
            <li>
              <RouterLink className="disabled-button-nav" >Happy Salon</RouterLink>
          </li>

        </ul>
      </li>
      <li>
        <RouterLink to="/profilom">Profilom</RouterLink>
      </li>

      {/*<li className={"has-droupdown"}>*/}
      {/*  <RouterLink to="/szolgaltatasok/arajanlat">Árajánlat</RouterLink>*/}
      {/*  <ul className="submenu">*/}
      {/*    <li>*/}
      {/*      <RouterLink to="/szolgaltatasok/arajanlat/csomag-arak">*/}
      {/*        Csomag árak*/}
      {/*      </RouterLink>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <RouterLink to="/szolgaltatasok/arajanlat/modul-arak">*/}
      {/*        Modul árak*/}
      {/*      </RouterLink>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <RouterLink to="/szolgaltatasok/arajanlat/szolgaltatas-arak">*/}
      {/*        Szolgáltatás árak*/}
      {/*      </RouterLink>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</li>*/}

      <li>
        <RouterLink to="/kapcsolat/ugyfelszolgalat">Ügyfélszolgálat</RouterLink>
      </li>

    </ul>
  );
};
export default Nav;
